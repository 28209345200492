/* HTML: <div class="loader"></div> */
.crownIcon {
  animation-delay: 2s;  
  animation: ci 4s infinite ease-in-out, ci2 4s infinite alternate ease-in-out;
  }

  @keyframes ci {to{transform: rotateZ(.05turn);}}
  @keyframes ci2 {from{transform: rotateZ(.05turn)} to{transform: rotateZ(0);}}


  

  
