
  #LandingPageBackgroundColor{
    background-color: #23242a;
  }
  pre {
    white-space: pre;
  }
  p {
      white-space: pre;
  }
  
.background{
  background-color: #23242a;
  height: 100vh;
}
.ReferenceImage{
    height: auto;
    max-width: 100vw;
}
.FullScreen{
  height: 100%;
  width: 100%;
}

  /* Waiting room stylesheet ---------------------------------------------------------
    Here is where we are starting the container setups for some objects on waiting 
    room screen.

    NOTE: Text-align: center should be on the container not on the text. it aligns
    all direct child text that is in the container element.

    NOTE for future Andrew: the container class should be relative, this will place it in
    the stack so you can put things on top of each other. the children should just exist without needing much work.
    Use the container to position the obj on screen like in unity as a parent gameobject.
  */
  .fordLogoContainer{
    position: relative;
    top: 12.5vh;
    width: 100vw;
    text-align: center;
    justify-content: center;
    align-items: center;

  }
  .fordLogoImageInContainer{
    position: relative;
    width: 42vw;
    height: auto;
  }
 
  .fordGamingTextInContainer {
    position: relative;
    font-size: 6.1vw;
    font-family: 'FordFont';
    color: rgb(255, 255, 255);
    letter-spacing: 0.04em;
    
  }

  /*
    NOTE: here you can see i have made the width 100VW, so that way the text is centered
    is already in the center of the screen at all times. 
  */
  .playerGreetingContainer{
    position: relative;
    top: 30vh;
    text-align: center;
    width: 100vw;
  }

  .playerGreeting_Hello{
    position: relative;
     font-size: 5.3vw;
     font-family: 'FordFont';
     text-align: center;
     color: rgb(255, 255, 255);
     letter-spacing: .08em;
     color: #DBDBDB;
 
   }
   .playerGreeting_PlayerName{
     position: relative;
     font-size: 8vw;
     font-family: 'FordFont';
     font-weight: bold;
     text-align: center;
     color: rgb(255, 255, 255);
     letter-spacing: 0.05em;
   }
   .editNameButtonContainer{
     position: relative;
     text-align: center;
     justify-content: center;
     align-items: center;
     width: 100vw;
     top: 31vh;
     
   }
   .EditNameButtonImage2{
    position: relative;
    width: 42.5vw;
    height: auto;
  }
  .EditTextNameOnButton2{
    position: absolute;
    left: 41vw;
    top: 35%;
    font-family: 'FordFont';
    font-style: normal;
    font-weight: 400;
    font-size: 3.3vw;
    letter-spacing: 0.06em;
    color: #DBDBDB;
  }
  
  .waitingRoomTextContainer{
    position: relative;
    text-align: center;
    top: 40.8vh;
  }
  .waitingRoomText{
    font-size: 3.6vw;
    font-weight: 400;
   line-height: 60px;
    font-family: 'FordFont';
    text-align: center;
    color: rgb(255, 255, 255);
    letter-spacing: 0.05em;
  }
  .selectGameText{
    font-size: 3.6vw;
    font-family: 'FordFont';
    text-align: center;
    color: rgb(255, 255, 255);
    letter-spacing: 0.05em;
    line-height: 1.83em;
    font-weight: 400;
  }
  .DebugContainer{
    position: relative;
    top: 52vh;
    left: 1vw;
  }
  .Debug_buttomPlayerID{
    font-family: "FordFont";
    font-size: 3vw;
    color: #DBDBDB;

  }

  .nameChangeOverlay_tintBlue{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.902;
    background-color:#2a2a30;
  }
  .nameChangeOverlay_tintBlack{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.302;
    background-color: black;
  }
  /* Pop up box------------------------------------- */
  .popupBoxContainer{
    position: absolute;
    width: 100vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 25vh;
  }
  
  
  .popUpBox{
    position: relative;
    width: 90vw;
    height: auto;
  }

  
  .EditNameButtonImageAccept_InPopup{
   position: absolute;
   width: 35vw;
   top: 60%;
   left: 14vw;
   height: auto;
 }
 .EditTextNameOnButtonAccept_InPopup{
   position: absolute;
   left: 25.5vw;
   top: 66%;
   font-family: 'FordFont';
   font-style: normal;
   font-weight: 400;
   font-size: 3.3vw;
   letter-spacing: 0.06em;
   color: white;
 }
 .EditNameButtonImageCancel_InPopup{
  position: absolute;
  width: 35vw;
  top: 60%;
  left: 52vw;
  height: auto;
}
.EditNameButtonImageCancel_InPopup_RaceResult{
  position: absolute;
  width: 35vw;
  top: 60%;
  left: 32%;
  height: auto;
}
.EditTextNameOnButtonCancel_InPopup_RaceResult{
  position: absolute;
  left: 45%;
  top: 65%;
  font-family: 'FordFont';
  font-style: normal;
  font-weight: 400;
  font-size: 3.3vw;
  letter-spacing: 0.06em;
  color: #DBDBDB;
}
.EditTextNameOnButtonCancel_InPopup{
  position: absolute;
  left: 63.5vw;
  top: 66%;
  font-family: 'FordFont';
  font-style: normal;
  font-weight: 400;
  font-size: 3.3vw;
  letter-spacing: 0.06em;
  color: #DBDBDB;
}

 .EnterNameText_InPopup{
  position: absolute;
  left: 23vw;
  top: 21%;
  font-family: 'FordFont';
  font-style: normal;
  font-weight: 400;
  font-size: 4.3vw;
  letter-spacing: 0.06em;
  color: white;
}
.EnterNameText_InPopup_RaceResults{
  position: absolute;
  left: 10%;
  width: 80%;
  top: 25%;
  font-family: 'FordFont';
  font-style: normal;
  font-weight: 400;
  font-size: 4.3vw;
  letter-spacing: 0.06em;
  color: white;
}
.nameEntryContainer{
  position: absolute;
  width: 70%;
  height: 18%;
  left: 15%;
  top: 38%;
}
.nameEntryBox2{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;

}
.inputClass_InPopup2{
  position: absolute;
  height: 98%;
  width: 98%;
 left: 0%;
  color: white;
  font-family: 'FordFont';
  font-size: 5vh;
  background: none;
  border: none;
}




.nameEntryBox{
  position: absolute;
  width: 70%;
  height: 18%;
  left: 15%;
  top: 38%;

}
.inputClass_InPopup{
  position: absolute;
  height: 16%;
  width: 68%;
  top: 40%;
  left: 15.8%;
  color: white;
  font-family: 'FordFont';
  font-size: 5vh;
  background: none;
  border: none;
}

  /* End waiting room stylesheet ----------------------------------------------------- */
  /*Header Controller -------------------------------*/
  .coinContainer{
    width: 7%;
}
.head-reference{ 
  position: relative;
  top: 0;
  width: 100%;
  height: 7.5vh;
  line-height: 7.5vh;
  display: flex;
  text-align: center;
  justify-content: center;
}
.controllerName{
  position: relative;
  font-family: 'FordFont';
  font-size: 38pt;
  text-shadow: 0 2px 4px rgba(0,0,0,.85);
  color: white;
}
  .coinContainerParent{
    position: inherit;
  }
  .coinImage3{
    position: relative;
    left: 100%;
    bottom: 10%;
    width: 100%;
    height: auto;
  }
  .coinImage{
    position: absolute;
    top: 1.87vh;
    left: 5.5%;
    width: 7%;
    height: auto;

  }
  .coinCounterText{
    position: absolute;
    left: 13.91%;
    top: 2.78vh;
    width: 7%;
    height: auto;
    font-family: 'FordFont';
    color: white;
    font-size: 4.5vw;
    
  }
  .hamburgerImage{
    position: absolute;
    right: 5.65%;
    top: 2.24vh;
    width: 6.5%;
    height: auto;
  }


  .debugButton{
    position: absolute;
    top: 20%;
    left: 20%;
  }

  /* Font Definitions here ------------------------------------------------------ */
  @font-face {
    font-family: FordFont;
    src: url(../Fonts/Antenna35FF_S_Rg.ttf) format('truetype');
  } 
  @font-face {
    font-family: FordFont_Md;
    src: url(../Fonts/Antenna35FF_S_Md.ttf) format('truetype');
  }

  @font-face {
    font-family: Rajdhani-Bold;
    src: url(../Fonts/Rajdhani-Bold.ttf) format('truetype');
  } 
  @font-face {
    font-family: Rajdhani-Medium;
    src: url(../Fonts/Rajdhani-Medium.ttf) format('truetype');
  }
  @font-face {
    font-family: Rajdhani;
    src: url(../Fonts/Rajdhani-Regular.ttf) format('truetype');
  } 

  @media only screen and (min-width: 600px)  {
    /*
    */
  }

