/* HTML: <div class="loader"></div> */
.loadingSpinner {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 10px solid rgba(255,255,255, .25);
    border-right-color: #FFD600;
    border-left-color: #FFD600;
    animation: ls 2s infinite ease-in-out, ls2 2s infinite alternate ease-in-out;;
  }

  @keyframes ls {to{transform: rotate(1turn)}}
  @keyframes ls2 {from{border-radius: 50%;} to{border-radius: 25%; border-width: 12px;}}

  

  
