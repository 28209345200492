
#tinygolfBackground{
    overscroll-behavior: contain;
    touch-action: manipulation;
    background-color: #905234;
}

.backgroundImage{
    width: 100vw;
    height: 100vh;
}

.TouchAreaContainer{
    position: relative;
    aspect-ratio: 1 / 1;
    top: 0;
    width: 90vw;
    border: 1px solid black;
    border-radius: 36px;
    box-shadow: 0 8px 4px rgba(0, 0, 0, 0.2), inset  8px 8px 40px 20px rgba(255, 235, 107, 0.2);
}
.TouchAreaImage{
    position: absolute;
    left: 0%;
    right: 0%;
    width: 100%;
    height: auto;
}
.InnerTouchContainer{
    position: absolute;
    left: 1%;
    top: 1%;
    width: 98%;
    height: 98%;
}

.PageStartPosition{
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
}
.reticlePosition{
    position: relative;
    width: 25%;
    height: 25%;
    top: 0%;
    left: 0%;
    pointer-events: none;
}
.reticleImage{
    position: relative;
    width: 100%;
    top: -50%;
    left: -50%;
    pointer-events: none;
   

}
.DebugCoordsText{
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0%;
}

/*Catapult Button css*/
.ButtonContainer{
    margin: 0 auto;
    background-size: 100% 100%;
    width: 200px;
    height: 100px;
}
.RowContainer{
    position: relative;
    margin: 0 auto;
    width: 98%;
    height: 56px;
    display: flex;
    flex-direction: row;
    flex-grow: unset;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
}
.ButtonText{
    font-family: 'FordFont';
    font-style: normal;
    font-weight: 400;
    font-size: 6vw;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0.06em;
    color: #000000;
    line-height:56px;

}

.ButtonText_Join{
    font-family: 'FordFont';
    font-style: normal;
    font-weight: 400;
    font-size: 6vw;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0.06em;
    color: #000000;
    line-height:100px;

}
.CatapultButtonTextInverted{
    position: absolute;
    top: 30%;
    font-family: 'FordFont';
    font-style: normal;
    font-weight: 400;
    font-size: 6vw;
    letter-spacing: 0.06em;
    color: #f3f1d5;

}

/* TrackerSlider */
.TouchSliderContainer{
    display: flex;
    align-items: center;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 4px rgba(0, 0, 0, 0.2), inset  8px 8px 40px 20px rgba(255, 244, 171, 0.2);
    border: 1px solid black;
    border-radius: 4px;
}

.TouchSliderBox{
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    height: 80%;
}

.TouchSliderMarker{
    position: absolute;
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    border:  .5px solid black;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.75), inset 2px 2px 4px rgba(0, 0, 0, 0.25) ;
    border-radius: 50%;
}

 /* Font Definitions here ------------------------------------------------------ */
 @font-face {
    font-family: FordFont;
    src: url(../Fonts/Antenna35FF_S_Rg.ttf) format('truetype');
  } 
  @font-face {
    font-family: FordFont_Md;
    src: url(../Fonts/Antenna35FF_S_Md.ttf) format('truetype');
  } 